import React, { useContext, useEffect, useState } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import Context from "../../functions/context";
import { useTranslation } from "react-i18next";
import { TOGGLE_SUBSCRIPTION_PAYMENT_MODAL } from "../../functions/constants";
import SubsPaymentModal from "./SubsPaymentModal";
import { subscriptionPlans } from "../../functions/api";
import PaymentCreateModal from "../cabinet/components/Payment/PaymentCreateModal";
import SocialNetworks from "../../components/SocialNetworks";
import TagManager from "react-gtm-module";

export default function Subscription() {
  const { t } = useTranslation();
  const [plans, setPlans] = useState([]);
  const {
    mainState: { visibleSubsPaymentModal, visibleCreatePaymentModal, customer },
    mainDispatch,
  } = useContext(Context);

  const selectTariff = (tariff) => {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });
    TagManager.dataLayer({
      dataLayer: {
        event: "view_cart",
        ecommerce: {
          item_name: tariff.name,
          item_brand: "Subscription",
          item_category: "Subscription",
          price: tariff.price,
          quantity: 1,
          currency: "USD",
        },
      },
    });
    mainDispatch({
      type: TOGGLE_SUBSCRIPTION_PAYMENT_MODAL,
      payload: { item: tariff, visible: true },
    });
  };

  const getPlans = async () => {
    try {
      const data = await subscriptionPlans();
      setPlans(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (customer) {
      TagManager.dataLayer({
        dataLayer: {
          event: "userID",
          UID: customer.id,
        },
      });
    }
    getPlans();
  }, []);

  return (
    <>
      <SocialNetworks />
      <BreadCrumb currentTitle="Subscription" />
      <section className="subscription-page">
        <div className="container">
          <h1 className="title">
            {t("Выберите тариф для получения больше возможностей")}
          </h1>
          <div className="table-responsive">
            <table>
              <tr>
                <td />
                {plans.map((plan, i) => (
                  <td>
                    <p>{plan.name}</p>
                    {i === 0 && <span>{t("Favorite")}</span>}
                  </td>
                ))}
              </tr>
              <tr>
                <td />
                {plans.map((plan, i) => (
                  <td key={i} style={{ whiteSpace: "nowrap" }}>
                    <h2>${plan.price}</h2>
                    <span>/{t(i === 0 ? "мес" : "год")}</span>
                    <button className="btn" onClick={() => selectTariff(plan)}>
                      {t("Выбрать")}
                    </button>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h5>{t("Цена за кг.")}</h5>
                </td>
                {plans.map((plan, i) => (
                  <td key={i}>
                    <h6>${plan.rate.rate_kg}</h6>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h5>{t("Бесплатная коробка")}</h5>
                </td>
                {plans.map((plan) => (
                  <td key={plan.id}>
                    {plan.offer_options.package_free && (
                      <img src="/images/icons/check.svg" alt="check" />
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h5>{t("Бесплатно заберём из дома")}</h5>
                </td>
                {plans.map((plan) => (
                  <td key={plan.id}>
                    {plan.offer_options.pickup_free && (
                      <img src="/images/icons/check.svg" alt="check" />
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h5>{t("Приоритет при доставке")}</h5>
                </td>
                {plans.map((plan) => (
                  <td key={plan.id}>
                    {plan.offer_options.priority_delivery && (
                      <img src="/images/icons/check.svg" alt="check" />
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h5>{t("Бесплатная доставка документов")}</h5>
                </td>
                {plans.map((plan) => (
                  <td key={plan.id}>
                    {plan.offer_options.delivery_free && (
                      <img src="/images/icons/check.svg" alt="check" />
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h5>{t("Бесплатный возврат товаров в США")}</h5>
                </td>
                {plans.map((plan) => (
                  <td key={plan.id}>
                    {plan.offer_options.refund_free && (
                      <img src="/images/icons/check.svg" alt="check" />
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h5>{t("Доставка по регионам УЗБ")}</h5>
                </td>
                {plans.map((plan, i) => (
                  <td key={i}>
                    <h6>${plan.offer_options.delivery_country_side}</h6>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h5>{t("Услуга Онлайн покупки(%)")}</h5>
                </td>
                {plans.map((plan, i) => (
                  <td key={i}>
                    <h6>{plan.offer_options.online_fee}%</h6>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h5>{t("Безплатная страховка до $100")}</h5>
                </td>
                {plans.map((plan, i) => (
                  <td key={i}>
                    <h6>{plan.offer_options.insurance_percent}%</h6>
                  </td>
                ))}
              </tr>
              <tr>
                <td>
                  <h5>{t("Скидка на услугу FedEx")}</h5>
                </td>
                {plans.map((plan, i) => (
                  <td key={i}>
                    <h6>{plan.offer_options.fedex_discount_percent}%</h6>
                  </td>
                ))}
              </tr>
            </table>
          </div>
        </div>
      </section>
      {visibleSubsPaymentModal && <SubsPaymentModal />}
      {visibleCreatePaymentModal ? <PaymentCreateModal /> : ""}
    </>
  );
}
