import request, { makeFormData } from "./request";
import { urlEncoder } from "./request-helper";
import { downloadFileFromBlob } from "./helper";

const qs = require("qs");

export const fetchShopList = (page, size) =>
  request
    .get("/website/shops", { params: { page, size } })
    .then(({ items }) => items);

export const postRegistration = (values) =>
  request
    .post("/cabinet/customers/register", values)
    .then((customer) => customer);

export const sentCode = (uuid) =>
  request.post(
    "/cabinet/payments/payme/resend-verify",
    uuid && {
      headers: {
        "x-order-token": uuid,
      },
    }
  );

export const addCustomerRegistration = (values) =>
  request
    .post("/cabinet/customers/add-customer", values)
    .then((customer) => customer);

export const updateProfile = (values) =>
  request.put("/cabinet/customers", values).then((customer) => customer);

export const changePassword = (values) =>
  request.post("/cabinet/customers/change-password", values);

export const verifyRegistration = (customerId, code) =>
  request
    .put(`/cabinet/customers/register/${customerId}/verification`, { code })
    .then((customer) => customer);

export function loginUser(username, password, rememberMe) {
  return request
    .post(
      "/cabinet/security/login_check",
      urlEncoder({
        _username: username,
        _password: password,
      }),
      {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
          "x-remember-me": rememberMe,
        },
      }
    )
    .then((data) => {
      localStorage.setItem("cabinet_token", data.token);
      return loadProfile(data.token).then((user) => {
        return { token: data.token, user };
      });
    });
}

export function sendVerificationCode(email) {
  return request.post("/cabinet/forgot-password", { email });
}

export function checkCode(code) {
  return request.post("/cabinet/forgot-password/check", { code });
}

export function subsActivation(status) {
  return request.patch("/cabinet/customers/manage-subscription", {
    autoRenewalSubscription: status,
  });
}

export function resetPassword(code, password) {
  return request.put("/cabinet/forgot-password/reset", { code, password });
}

export function loadProfile(token = undefined) {
  if (!token) {
    token = localStorage.getItem("cabinet_token");
  }
  return request
    .get("/cabinet/customers/me", {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then((user) => user);
}

export function getOrderDetails(id) {
  return request.get(`/cabinet/options/orders/${id}`);
}

export const shopRate = () =>
  request.get("/cabinet/options/shop-rate").then((data) => data);

export const loadRegions = (params, page, size) =>
  request.get("/regions", { params: { ...params, page, size } });

export const loadAddressLimits = (ids) =>
  request.get("/cabinet/address/period-limits", { params: { ids } });

export const loadDistricts = (params, page, size) =>
  request.get("/districts", { params: { ...params, page, size } });

export const loadAddress = (params, page, size) =>
  request.get("/cabinet/address", { params: { ...params, page, size } });

export const createAddress = (values) =>
  request
    .post("/cabinet/address", makeFormData(values), {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((address) => address);

export const updateAddress = (id, values) =>
  request
    .post(
      `/cabinet/address/${id}`,
      makeFormData({ ...values, _method: "put" }),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((address) => address);

export const deleteAddress = (id) => request.delete(`/cabinet/address/${id}`);

export const loadOrders = (query) =>
  request.get(`/cabinet/orders?${query}`).then((res) => res);

export const readOrder = (id) =>
  request.get(`/cabinet/orders/${id}`).then((order) => order);

export const loadBoxes = (ids) =>
  request
    .get("/cabinet/orders/boxes", { params: { ids } })
    .then(({ items }) => items);

export const findTrackingBox = (id) =>
  request
    .get("/cabinet/orders/tracking-box", { params: { id } })
    .then((res) => res);

export const loadTracking = (filter) =>
  request.get(`/cabinet/trackings?${filter}`).then((res) => res);

export const loadTrackingSummery = () =>
  request.get("/cabinet/trackings/summery").then((res) => res);

export const loadOrdersSummery = () =>
  request.get("/cabinet/orders/summery").then((res) => res);

export const createTracking = (values) =>
  request.post("/cabinet/trackings", values).then((item) => item);

export const updateTracking = (id, values) =>
  request.put(`/cabinet/trackings/${id}`, values).then((item) => item);

export const updatePartialTracking = (id, values) =>
  request.patch(`/cabinet/trackings/${id}`, values).then((item) => item);

export const deleteTracking = (id) =>
  request.delete(`/cabinet/trackings/${id}`);

export const createShop = (values) =>
  request.post("/cabinet/shops", values).then((shop) => shop);

export const updateShop = (id, values) =>
  request.put(`/cabinet/shops/${id}`, values).then((shop) => shop);

export const updateRecordShop = (id, values) =>
  request.put(`/cabinet/shop-records/${id}`, values).then((shop) => shop);

export const updateRecordService = (id, additionalServices) =>
  request
    .put(`/cabinet/shop-records/${id}/services`, { additionalServices })
    .then((record) => record);

export const readShopRecord = (id) =>
  request.get(`/cabinet/shop-records/${id}`).then((record) => record);

export const loadShops = (filter) =>
  request.get(`/cabinet/shops?${filter}`).then((res) => res);

export const deleteShop = (id) => request.delete(`/cabinet/shops/${id}`);

export const deleteShopRecord = (id) =>
  request.delete(`/cabinet/shop-records/${id}`);

export const loadShopSummery = () =>
  request.get("/cabinet/shops/summery").then((res) => res);

export const customerUsedBonusPrice = () =>
  request.get("/cabinet/customers/used-bonus-price").then((total) => total);

export const sendInvite = (params) =>
  request.post("/cabinet/customers/invite-friend", params);

export const postFeedbackWebsite = (values) =>
  request.post("/website/feedback", values);

export const postFranchise = (values) =>
  request.post("/website/franchise-contact", values);

export const postContactWebsite = (values) =>
  request.post("/website/contact", values);

export const fetchMainStats = () =>
  request.get("/cabinet/options/main-stats").then((res) => res);

export const fetchOrderByUlid = (ulid) =>
  request.get(`/cabinet/options/orders/${ulid}`).then((res) => res);

export const fetchStripePublishingKey = (uuid) =>
  request
    .get(
      `/cabinet/options/stripe-publishable-key`,
      uuid && {
        headers: {
          "x-order-token": uuid,
        },
      }
    )
    .then((key) => key);

export const createStripeIntents = (resource, resourceId, amount, uuid) =>
  request
    .post(
      "/cabinet/payments/stripe/create-intents",
      {
        resource,
        resourceId,
        amount,
      },
      uuid && {
        headers: {
          "x-order-token": uuid,
        },
      }
    )
    .then((securityKey) => securityKey);

export const fetchEstimatedShipment = (senderCountry) =>
  request
    .get(`/cabinet/options/estimated-shipment/${senderCountry}`)
    .then((shipment) => shipment);

export const getMessages = (query) => {
  return request.get(`/cabinet/comments?${query}`).then((messages) => messages);
};
export const sendMessages = (mess) => {
  return request
    .post(`/cabinet/comments`, {
      message: mess,
    })
    .then((messages) => messages);
};

export const deleteMessages = (messageId) => {
  return request
    .delete(`cabinet/comments/${messageId}`)
    .then((messages) => messages);
};

export const readAllMessages = () => {
  return request.put(`/cabinet/comments/make-read`);
};

export const getUnreadMessages = () => {
  return request.get(`/cabinet/comments/unread-count`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("cabinet_token")}`,
    },
  });
};

export const addPaymeBankCard = (cardNumber, expire, save, uuid) =>
  request.post(
    "/cabinet/payments/payme/bank-cards",
    {
      cardNumber,
      expire,
      save,
    },
    uuid && {
      headers: {
        "x-order-token": uuid,
      },
    }
  );

export const paymentPay = (
  resource,
  resourceId,
  code,
  amount,
  currencyRate,
  exchangedAmount,
  uuid
) => {
  return request.post(
    "/cabinet/payments/payme/pay",
    {
      resource,
      resourceId,
      code,
      amount,
      currencyRate,
      exchangedAmount,
    },
    uuid && {
      headers: {
        "x-order-token": uuid,
      },
    }
  );
};

export const getCurrencyRate = () =>
  request.get("/cabinet/payments/currency-rate").then((rate) => rate);

export const postShopRate = (shopId, rate) =>
  request
    .post(
      `/cabinet/reviews/shops/${shopId}`,
      { rate },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cabinet_token")}`,
        },
      }
    )
    .then((newRate) => newRate);

export const loadProposalProducts = () =>
  request.get("/cabinet/options/proposal-products").then(({ items }) => items);

export const uploadPassportPhotoRequest = (
  signature,
  passportFile,
  passportBackFile
) => {
  return request.post(
    `/cabinet/orders/address/passport-upload?signature=${signature}`,
    makeFormData({ passportFile, passportBackFile, _method: "PATCH" }),
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const loadFedexItems = (params) =>
  request.get(`/cabinet/fedexes?${qs.stringify(params)}`).then((data) => data);

export const getRates = (values) =>
  request
    .post(`/cabinet/fedexes/calculate-rate`, values)
    .then((services) => services);

export const createFedex = (values) =>
  request.post(`/cabinet/fedexes`, values).then((fedex) => fedex);

export const downloadRecordLabels = (fedexId, fileIds) => {
  fileIds.forEach((fileId, index) => {
    request
      .get(`/cabinet/fedexes/files/${fileId}/download`, {
        responseType: "blob",
      })
      .then((data) => {
        downloadFileFromBlob(data, `fedex_${fedexId}_${index}_label`);
      });
  });
};
export const downloadDeclaration = () => {
  request
    .get(`/cabinet/fedexes/files/empty-declaration`, { responseType: "blob" })
    .then((data) => {
      downloadFileFromBlob(data, `fedex_declaration_template`);
    });
};

export const resendEmail = (fedexId, email) =>
  request.put(`/cabinet/fedexes/${fedexId}/resend-email`, { email });

export const fedexStoreDeclaration = (fedexId, values) =>
  request.put(`/cabinet/fedexes/${fedexId}/update-invoice`, values);

export const fedexRead = (fedexId) =>
  request.get(`/cabinet/fedexes/${fedexId}`).then((data) => data);

export const loadOfficeAddress = () =>
  request.get("/cabinet/options/office-address").then((address) => address);

export const subscriptionPlans = () =>
  request.get("/cabinet/options/subscription-plans").then((plans) => plans);

export const createClaims = (values) => request.post("/cabinet/claims", values);

export const claimsList = () => request.get("/cabinet/claims");

export const claimById = (id) => request.get(`/cabinet/claims/${id}`);
