import { Field, Form } from "react-final-form";
import {
  composeValidators,
  minLengthConstraint,
  requiredConstraint,
} from "../../../functions/validator";
import _cloneDeep from "lodash/cloneDeep";
import { postRegistration } from "../../../functions/api";
import { normalizeErrorFields } from "../../../functions/request-helper";
import React, { useContext, useRef, useState } from "react";
import Context from "../../../functions/context";
import { useOnClickOutside } from "../../../functions/useOnClickOutside";
import { useTranslation } from "react-i18next";
import { getBaseUrl } from "../../../functions/request";
import PasswordInput from "../../../components/PasswordInput";
import TagManager from "react-gtm-module";

export default function RegisterForm() {
  const [submitting, setSubmitting] = useState(false);
  const { setCustomer, setPassword } = useContext(Context);
  const [phonePrefix, setPhonePrefix] = useState(
    localStorage.getItem("country") === "USA" ? "+1" : "+998"
  );
  const ref = useRef();
  const [showCountrySelector, setShowCountrySelector] = useState(false);
  const { t } = useTranslation();

  const onSubmit = async (values) => {
    let sendValues = _cloneDeep(values);

    setSubmitting(true);
    try {
      if (sendValues.agree) {
        delete sendValues.agree;
      }
      if (sendValues.comparePassword) {
        delete sendValues.comparePassword;
      }
      if (sessionStorage.getItem("referral")) {
        sendValues.referral = sessionStorage.getItem("referral");
      }
      if (sendValues.phones?.[0].indexOf(phonePrefix) < 0) {
        sendValues.phones[0] = `${phonePrefix}${sendValues.phones[0]}`;
      }
      let customer = await postRegistration(sendValues);
      TagManager.dataLayer({
        dataLayer: {
          event: "registration_soi",
        },
      });
      setPassword(sendValues.credential.password);
      setCustomer(customer);
      setSubmitting(false);
    } catch (res) {
      setSubmitting(false);
      if (res?.response?.status === 422) {
        const error = res.response.data;
        const fieldErrors = normalizeErrorFields(error, sendValues);
        if (Object.keys(fieldErrors).length) {
          return fieldErrors;
        }
      }
    }
  };
  useOnClickOutside(ref, () => setShowCountrySelector(false));
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => {
        return (
          <form onSubmit={handleSubmit} className="row">
            <Field
              name="firstName"
              validate={composeValidators(requiredConstraint)}
            >
              {({ input, meta }) => {
                return (
                  <div className="col-12 input-margin-b">
                    <input
                      {...input}
                      type="text"
                      placeholder={`${t("Name")}*`}
                      className={`${
                        (meta.error || meta.submitError) && meta.touched
                          ? `error-border`
                          : ``
                      }`}
                    />
                  </div>
                );
              }}
            </Field>
            <Field
              name="lastName"
              validate={composeValidators(requiredConstraint)}
            >
              {({ input, meta }) => {
                return (
                  <div className="col-12 input-margin-b">
                    {/* <label htmlFor='lastName'>Last name</label> */}
                    <input
                      {...input}
                      type="text"
                      id="lastName*"
                      className={`${
                        (meta.error || meta.submitError) && meta.touched
                          ? `error-border`
                          : ``
                      }`}
                      placeholder={`${t("Surname")}*`}
                    />
                  </div>
                );
              }}
            </Field>
            <Field
              name="phones.0"
              validate={composeValidators(
                requiredConstraint,
                minLengthConstraint(phonePrefix === "+1" ? 10 : 9)
              )}
            >
              {({ input, meta }) => {
                return (
                  <div className="col-12 input-margin-b" ref={ref}>
                    <div className="input-group">
                      <button
                        onClick={() =>
                          setShowCountrySelector(!showCountrySelector)
                        }
                        className="bt btt btn-outline-secondar"
                        type="button"
                      >
                        <span className="d-flex align-items-center ">
                          <img
                            src={`/images/${
                              phonePrefix === "+1" ? "usa" : "uzb"
                            }.svg`}
                            alt=""
                            width="44"
                          />
                          {showCountrySelector ? (
                            <i className="ri-arrow-up-s-fill"></i>
                          ) : (
                            <i className="ri-arrow-down-s-fill"></i>
                          )}
                          {phonePrefix}
                        </span>
                      </button>
                      <input
                        pattern={`^[0-9]{0,${phonePrefix === "+1" ? 10 : 9}}$`}
                        onFocus={() => setShowCountrySelector(false)}
                        onChange={(e) => {
                          if (e.target.validity.valid) {
                            input.onChange(e.target.value);
                          } else {
                            e.target.value = input.value;
                          }
                        }}
                        type="text"
                        id="number"
                        className={`${
                          (meta.error || meta.submitError) && meta.touched
                            ? `error-border`
                            : ``
                        } form-control register-number-input number ${
                          phonePrefix === "+998" ? "uz" : ""
                        }`}
                      />
                      <ul
                        className={`dropdown-menu ${
                          showCountrySelector ? "show" : ""
                        }`}
                      >
                        <li>
                          <a
                            onClick={() => {
                              setPhonePrefix("+1");
                              setShowCountrySelector(false);
                            }}
                            className={`dropdown-item ${
                              phonePrefix === "+1" ? "active" : ""
                            }`}
                          >
                            <span>
                              <img src="/images/usa.svg" alt="" />
                            </span>
                            United States
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              setPhonePrefix("+998");
                              setShowCountrySelector(false);
                            }}
                            className={`dropdown-item ${
                              phonePrefix === "+998" ? "active" : ""
                            }`}
                          >
                            <span>
                              <img src="/images/uzb.svg" alt="" />
                            </span>
                            Uzbekistan
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              }}
            </Field>
            <Field
              name="email"
              validate={composeValidators(requiredConstraint)}
            >
              {({ input, meta }) => {
                return (
                  <div className="col-12 input-margin-b">
                    {/* <label htmlFor='email'>Email</label> */}
                    <input
                      className={`content-right-body-input ${
                        (meta.error || meta.submitError) && meta.touched
                          ? `error-border`
                          : ``
                      }`}
                      {...input}
                      type="text"
                      id="email"
                      placeholder={`${t("Email")}*`}
                    />
                    {meta.submitError ? (
                      <span className="help-block">{t(meta.submitError)}</span>
                    ) : null}
                  </div>
                );
              }}
            </Field>
            <Field
              name="credential.password"
              validate={composeValidators(
                requiredConstraint,
                minLengthConstraint(6)
              )}
            >
              {({ input, meta }) => (
                <PasswordInput
                  input={input}
                  meta={meta}
                  label={`${t("Password")}*`}
                />
              )}
            </Field>
            <div className="col-12 d-flex align-items-start">
              <Field
                name="agree"
                type="checkbox"
                value="1"
                validate={composeValidators(requiredConstraint)}
              >
                {({ input, meta }) => {
                  return (
                    <>
                      <input
                        {...input}
                        id="loginOffertaCheckbox"
                        className={`checkbox ${
                          (meta.error || meta.submitError) && meta.touched
                            ? `error-border`
                            : ``
                        }`}
                      />
                      <label
                        className="checkbox-label content-right-body-checkbox-label"
                        htmlFor="loginOffertaCheckbox"
                      >
                        {t("I agree to all the")}{" "}
                        <a href="/terms" target="_blank">
                          {t("Term, Privacy")}
                        </a>{" "}
                        {t("and")}
                        <a href="/terms" target="_blank">
                          {" "}
                          {t("Fees")}
                        </a>
                      </label>
                    </>
                  );
                }}
              </Field>
            </div>
            <div className="col-12 btns" style={{ marginTop: "35px" }}>
              <input
                className="login-btn btn"
                type="submit"
                value={t("menu.Registration")}
                disabled={submitting}
              />
              <div className="social-btns d-inline-flex">
                <a
                  className="btn google-btn"
                  href={`${getBaseUrl()}/cabinet/oauth/connect/google`}
                >
                  <img src="/images/google_icon.png" />
                </a>
                <a
                  href={`${getBaseUrl()}/cabinet/oauth/connect/facebook`}
                  className="btn facebook-btn"
                >
                  <img src="/images/facebook_icon.png" />
                </a>
              </div>
            </div>
          </form>
        );
      }}
    />
  );
}
