import React, { useContext, useMemo, useState } from "react";
import { paymentPay, sentCode } from "../../../../functions/api";
import { normalizeErrorFields } from "../../../../functions/request-helper";
import { Field, Form } from "react-final-form";
import {
  composeValidators,
  requiredConstraint,
} from "../../../../functions/validator";
import {
  priceFormatter,
  shopEventRecords,
  trackingEventOrder,
} from "../../../../functions/helper";
import TimerButton from "../../../../components/TimerButton";
import { useTranslation } from "react-i18next";
import Context from "../../../../functions/context";
import TagManager from "react-gtm-module";

export default function PaymeConfirmForm({
  uuid,
  resource,
  resourceId,
  amount,
  successCallback,
  currencyRate,
}) {
  const { t } = useTranslation();
  const {
    mainState: { currentItem, selectedTariff },
  } = useContext(Context);
  const [disabled, setDisabled] = useState(false);
  const [sendCode, setSendCode] = useState(0);
  const exchangedAmount = useMemo(() => {
    return Math.round(parseFloat(amount) * parseFloat(currencyRate.Rate));
  }, [currencyRate]);

  function reSendCode() {
    setSendCode(60);
    sentCode(uuid).catch((error) => {
      setSendCode(0);
      console.log(error);
    });
  }

  const purchaseEvent = () => {
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    });
    if (resource === 3) {
      const items = [
        {
          item_name: selectedTariff.name,
          item_brand: "Subscription",
          item_category: "Subscription",
          price: selectedTariff.price,
          quantity: 1,
          currency: "USD",
        },
      ];
      TagManager.dataLayer({
        dataLayer: {
          event: "purchase",
          transaction_id: selectedTariff.id,
          value: amount,
          ecommerce: {
            items: items,
          },
        },
      });
    } else if (resource === 2) {
      const items = shopEventRecords([currentItem]);
      TagManager.dataLayer({
        dataLayer: {
          event: "purchase",
          ecommerce: {
            transaction_id: resourceId,
            value: amount,
            items: items,
          },
        },
      });
    } else {
      const items = trackingEventOrder([currentItem]);
      TagManager.dataLayer({
        dataLayer: {
          event: "purchase",
          ecommerce: {
            transaction_id: resourceId,
            value: amount,
            items: items,
          },
        },
      });
    }
  };

  const onSubmit = async (values) => {
    try {
      setDisabled(true);
      await paymentPay(
        resource,
        resourceId,
        values.code,
        amount,
        currencyRate.Rate,
        exchangedAmount,
        uuid
      );
      purchaseEvent();
      console.log("Payment succeeded 🎉");
      successCallback();
    } catch (res) {
      if (res?.response?.status === 422) {
        const error = res.response.data;
        const fieldErrors = normalizeErrorFields(error);
        if (Object.values(fieldErrors).length) {
          return fieldErrors;
        }
      }
    } finally {
      setDisabled(false);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit} className="row">
            <div className="col-md-6">
              <Field
                name="code"
                type="text"
                validate={composeValidators(requiredConstraint)}
              >
                {({ input, meta }) => (
                  <div className="password-input">
                    <input
                      {...input}
                      placeholder={"Code*"}
                      className="form-control"
                    />
                  </div>
                )}
              </Field>
            </div>
            <div className="col-12">
              <div className="total">
                <p>{t("Сумма оплаты")}:</p>
                <h4>{priceFormatter(exchangedAmount)} UZS</h4>
              </div>
            </div>
            <div className="col-6">
              <button
                type="submit"
                className="send-button btn"
                disabled={disabled}
              >
                {!disabled ? t("Оплатить") : t("Process")}
              </button>
            </div>
            <div className="col-6">
              <TimerButton onClick={reSendCode} />
            </div>
          </form>
        );
      }}
    />
  );
}
