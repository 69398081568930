import {
  ACCOUNT_MODAL,
  ADD_UPDATE_ORDER,
  ADD_UPDATE_SHOP,
  ADD_UPDATE_TRACKING,
  BALLS_MODAL,
  CARD_ITEM_MODAL,
  LOAD_ADDRESS,
  LOAD_ORDERS,
  LOAD_PROFILE,
  LOAD_SHOP,
  LOAD_TRACKING,
  PAYMENT_MODAL,
  REMOVE_SHOP_RECORD,
  REMOVE_TRACKING,
  SET_CURRENT_ADDRESS,
  SET_CURRENT_ITEM,
  SET_LOCALE,
  SET_RESET_CODE,
  SET_SECTION,
  SET_SELECTED_ADDRESS,
  SET_TRACKING_ORDER,
  SHOWED_MODAL,
  TOGGLE_ADD_MODAL,
  TOGGLE_ADDRESS_MODAL,
  TOGGLE_CALCULATOR_MODAL,
  TOGGLE_IMAGE_MODAL,
  TOGGLE_ORDER_INVOICE,
  TOGGLE_PAYMENT_MODAL,
  TOGGLE_SERVICE_MODAL,
  UNSET_PROFILE,
  UNSET_TRACKING_ORDER,
  UPDATE_PAYMENT_GATEWAY,
  UPDATE_PAYMENT_STEP,
  UPDATE_SHOP_RECORD,
  UPDATE_STEP_MODAL,
  UNREAD_COUNT,
  TOGGLE_FEDEX_EMAIL_MODAL,
  SET_SUBSCRIPTION,
  SET_CLAIMS,
  TOGGLE_SUBSCRIPTION_PAYMENT_MODAL,
} from "../functions/constants";
import { addOrUpdateModel, deleteArrayRow } from "../functions/request-helper";
import dayjs from "dayjs";

export const initialState = {
  customer: undefined,
  modalActive: false,
  visibleAddressModal: false,
  addresses: {
    1: [],
    2: [],
  },
  trackings: [],
  shops: [],
  orders: [],
  total_count: 0,
  resetCode: null,
  visibleServiceModal: false,
  visibleImageModal: false,
  visibleCardItemModal: false,
  paymentModal: false,
  visibleBallsModal: false,
  visibleAccountModal: false,
  visibleCalculatorModal: false,
  mainTracking: {
    box: null,
    step: 0,
  },
  mainLocale: "en",
  formInitialValues: {},
  unreadCount: 0,
  currencyRate: null,
  currentFedex: null,
  visibleEmailModal: false,
  subscription: {
    subStep: 1,
    tariff: null,
    data: null,
  },
  claims: {
    data: null,
    step: 0,
  },
  selectedTariff: null,
  visibleSubsPaymentModal: false,
};

export const MainReducer = (state, action) => {
  switch (action.type) {
    case LOAD_PROFILE:
      return {
        ...state,
        customer: action.payload,
      };
    case UNSET_PROFILE: {
      return {
        ...state,
        customer: undefined,
      };
    }
    case SHOWED_MODAL:
      return {
        ...state,
        modalActive: action.payload,
      };
    case LOAD_TRACKING:
      return {
        ...state,
        trackings: action.payload.items,
        totalCount: action.payload.total_count,
        page: action.payload.page,
      };
    case LOAD_ORDERS:
      return {
        ...state,
        orders: action.payload.items,
        totalCount: action.payload.total_count,
        page: action.payload.page,
      };
    case ADD_UPDATE_TRACKING:
      return {
        ...state,
        trackings: [...addOrUpdateModel(state.trackings, action.payload)],
      };
    case UNREAD_COUNT:
      return {
        ...state,
        unreadCount: action.payload,
      };
    case ADD_UPDATE_ORDER:
      return {
        ...state,
        orders: [...addOrUpdateModel(state.orders, action.payload)],
      };
    case REMOVE_TRACKING:
      return {
        ...state,
        trackings: [...deleteArrayRow(state.trackings, "id", action.payload)],
        total_count: state.total_count - 1,
      };
    case LOAD_SHOP:
      return {
        ...state,
        shops: action.payload.items,
        totalCount: action.payload.total_count,
        page: action.payload.page,
      };
    case SET_CLAIMS:
      return {
        ...state,
        claims: action.payload,
      };
    case ADD_UPDATE_SHOP:
      return {
        ...state,
        shops: [...addOrUpdateModel(state.shops, action.payload)],
      };
    case UPDATE_SHOP_RECORD:
      let updateShop = state.shops.find((sh) => sh.id === action.payload.shop);
      updateShop.records = addOrUpdateModel(updateShop.records, action.payload);

      return {
        ...state,
        shops: [...addOrUpdateModel(state.shops, updateShop)],
      };
    case REMOVE_SHOP_RECORD:
      let shop = state.shops.find((sh) => sh.id === action.payload.shop);
      shop.records = deleteArrayRow(shop.records, "id", action.payload.id);

      return {
        ...state,
        shops: [...addOrUpdateModel(state.shops, shop)],
      };
    case SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.payload.item,
        update: action.payload?.update,
      };
    case TOGGLE_ORDER_INVOICE:
      return {
        ...state,
        currentItem: action.payload?.item,
        visibleInvoiceModal: action.payload.visible,
      };

    case SET_SECTION:
      return {
        ...state,
        section: action.payload,
        currentStep: 1,
        formInitialValues:
          action.payload === "tracking"
            ? { products: [undefined], currentStep: 1, recipientAddress: null }
            : { records: [undefined], currentStep: 1, recipientAddress: null },
      };
    case UPDATE_STEP_MODAL:
      return {
        ...state,
        currentStep: action.payload,
      };
    case TOGGLE_ADD_MODAL:
      return {
        ...state,
        showAddModal: action.payload.visible,
      };
    case TOGGLE_ADDRESS_MODAL:
      return {
        ...state,
        visibleAddressModal: action.payload,
      };
    case LOAD_ADDRESS:
      return {
        ...state,
        addresses: {
          ...state.addresses,
          [action.payload.country]: action.payload.items,
        },
      };
    case SET_CURRENT_ADDRESS:
      return {
        ...state,
        currentAddress: action.payload,
      };
    case SET_SELECTED_ADDRESS:
      return {
        ...state,
        selectedAddress: action.payload,
      };
    case SET_RESET_CODE:
      return {
        ...state,
        resetCode: action.payload,
      };
    case TOGGLE_SERVICE_MODAL:
      return {
        ...state,
        visibleServiceModal: action.payload.visible,
        currentItem: action.payload?.item || null,
      };
    case TOGGLE_IMAGE_MODAL: {
      return {
        ...state,
        visibleImageModal: action.payload.visible,
        images: action.payload.images,
      };
    }
    case CARD_ITEM_MODAL: {
      return {
        ...state,
        visibleCardItemModal: !state.visibleCardItemModal,
      };
    }
    case PAYMENT_MODAL:
      return {
        ...state,
        visiblePaymentModal: action.payload.visible,
        currentItem: action.payload?.item || null,
      };
    case TOGGLE_SUBSCRIPTION_PAYMENT_MODAL:
      return {
        ...state,
        visibleSubsPaymentModal: action.payload.visible,
        selectedTariff: action.payload?.item || null,
      };
    case BALLS_MODAL:
      return {
        ...state,
        visibleBallsModal: action.payload.visible,
        currentItem: action.payload?.item || null,
      };
    case ACCOUNT_MODAL:
      return {
        ...state,
        visibleAccountModal: action.payload.visible,
        currentItem: action.payload?.item || null,
      };
    case TOGGLE_CALCULATOR_MODAL: {
      return {
        ...state,
        visibleCalculatorModal: !state.visibleCalculatorModal,
      };
    }
    case SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
      };
    case TOGGLE_FEDEX_EMAIL_MODAL: {
      return {
        ...state,
        visibleEmailModal: action.payload.visible,
        currentFedex: action.payload.current,
      };
    }
    case SET_TRACKING_ORDER: {
      let {
        box,
        order,
        sender_address,
        recipient_address,
        shipment,
        delivery,
      } = action.payload;
      let step, status, estimatedDate;

      if (shipment?.arrival_time) {
        estimatedDate = shipment.arrival_time;
      }
      switch (order.status) {
        case "location":
        case "pending":
        case "packed":
          step = 1;
          status = "pending";
          estimatedDate = dayjs(order.created_at).add(5, "day");
          break;
        case "sent":
          step = 2;
          status = order.status;
          if (shipment && shipment.sent_at && !estimatedDate) {
            estimatedDate = dayjs(shipment.sent_at).add(3, "day");
          }
          break;
        case "in_customs":
          step = 3;
          status = order.status;
          if (shipment && shipment.sent_at && !estimatedDate) {
            estimatedDate = dayjs(shipment.sent_at).add(4, "day");
          }
          break;
        case "arrived":
        case "out_driver":
          step = 4;
          status = order.status;
          if (shipment && shipment.sent_dt && !estimatedDate) {
            estimatedDate = dayjs(shipment.sent_dt).add(4, "day");
          }
          break;
        case "delivered":
          step = 5;
          status = order.status;
          break;
      }
      if (typeof estimatedDate !== "string") {
        estimatedDate = estimatedDate?.format("MM/DD/YYYY | HH:mm");
      }

      return {
        ...state,
        mainTracking: {
          box,
          order,
          sender_address,
          recipient_address,
          step,
          status,
          shipment,
          estimatedDate,
          delivery,
        },
      };
    }
    case UNSET_TRACKING_ORDER: {
      return {
        ...state,
        mainTracking: {
          box: null,
          step: 0,
        },
      };
    }
    case SET_LOCALE: {
      return {
        ...state,
        mainLocale: action.payload,
      };
    }
    case TOGGLE_PAYMENT_MODAL: {
      return {
        ...state,
        resource: action.payload.visible ? action.payload.resource : null,
        resourceId: action.payload.visible ? action.payload.resourceId : null,
        amount: action.payload.visible ? action.payload.amount : null,
        paymentGateway: action.payload.visible ? "stripe" : null,
        visibleCreatePaymentModal: action.payload.visible,
        saveCard: action.payload?.saveCard || false,
        paymentStep: 1,
        uuid: action.payload.uuid ?? null,
      };
    }
    case UPDATE_PAYMENT_STEP: {
      return {
        ...state,
        paymentStep: action.payload.step,
      };
    }
    case UPDATE_PAYMENT_GATEWAY: {
      return {
        ...state,
        paymentGateway: action.payload.gateway,
        currencyRate: action.payload.currencyRate,
      };
    }
    default:
      return state;
  }
};
